import {classNames} from '@shopify/css-utilities';
import {Thumbnail, type ThumbnailProps} from '@shopify/polaris-internal';
import React from 'react';

import styles from './ThumbnailWithFallback.module.css';

/**
 * A simple wrapper around Thumbnail that will set a default icon if none is set.
 * Also allows a different fallback to be used if the default ImageIcon is not wanted
 */
export function ThumbnailWithFallback({
  fallbackSource = 'image',
  source = fallbackSource,
  alt = '',
  subdued = false,
  fullSizeIcon = false,
  backgroundSecondary = false,
  cover = false,
  ...rest
}: ThumbnailWithFallbackProps) {
  return (
    <div
      className={classNames(styles.ThumbnailWithFallback, {
        [styles.Subdued]: subdued,
        [styles.FullSizeIcon]: fullSizeIcon,
        [styles.BackgroundSecondary]: backgroundSecondary,
        [styles.Cover]: cover,
        [styles.Contain]: !cover,
      })}
    >
      <Thumbnail source={source} alt={alt} {...rest} />
    </div>
  );
}

export interface ThumbnailWithFallbackProps
  extends Omit<ThumbnailProps, 'source' | 'alt'> {
  /**
   * The thumbnail source
   * @default @param fallbackSource=ImageIcon
   */
  source: ThumbnailProps['source'] | undefined;

  /**
   * If you want a different fallback instead of the default ImageIcon
   * @default ImageIcon
   */
  fallbackSource?: ThumbnailProps['source'];

  /**
   * Alt text for the thumbnail
   * @default ''
   */
  alt?: ThumbnailProps['alt'];

  /**
   * Whether we want the icon (svg) inside to match the Thumbnail's height and width
   * @default false
   */
  fullSizeIcon?: boolean;

  subdued?: boolean;

  /**
   * Whether we want the background to be a different secondary color
   * @default false
   */
  backgroundSecondary?: boolean;

  /**
   * Whether we want the image to be displayed with object-fit: cover
   * @default false
   */
  cover?: boolean;
}
